<template>
    <!-- BEGIN login -->
    <div class="login login-with-news-feed">
        <!-- BEGIN news-feed -->
        <div class="news-feed">
            <div class="news-image" style="background-image: url(https://cdni.iconscout.com/illustration/premium/thumb/pizza-delivery-boy-2706544-2263641.png)"></div>
            <!-- <video
                src="https://css-tricks-post-videos.s3.us-east-1.amazonaws.com/708209935.mp4"
                autoplay
                loop
                playsinline
                muted
                class="news-image tw-h-full"
                style="object-fit: cover; width: 100vw"
            ></video> -->
            <div class="news-caption">
                <h4 class="caption-title"><b>JD Express</b> Admin App</h4>
                <p>
                    Delivery all products, event far or near! safe and fast.
                </p>
            </div>
        </div>
        <!-- END news-feed -->

        <!-- BEGIN login-container -->
        <div class="login-container">
            <!-- BEGIN login-header -->
            <div class="login-header mb-30px">
                <div class="brand">
                    <div class="d-flex align-items-center">
                        <span class="logo"></span>
                        <b>JD</b> Express
                    </div>
                    <small>The Solution You Are All Need...</small>
                </div>
                <div class="icon">
                    <i class="fa fa-sign-in-alt"></i>
                </div>
            </div>
            <!-- END login-header -->

            <!-- BEGIN login-content -->
            <div class="login-content">
                <div
                    class="alert alert-danger fade show ts-mb-4"
                    v-show="errorMessage != ''"
                >
                    {{ errorMessage }}
                </div>
                <form class="fs-13px" @submit.prevent="login">
                    <div class="form-floating mb-15px">
                        <input
                            v-model.trim="model.username"
                            type="text"
                            class="form-control h-45px fs-13px"
                            placeholder="Email Address"
                            id="username"
                            :class="{ 'is-invalid': errors.has('username') }"
                        />
                        <label
                            for="username"
                            class="d-flex align-items-center fs-13px text-gray-600"
                            >User Name</label
                        >
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('username')"
                        >
                            {{ errors.first("username") }}
                        </div>
                    </div>
                    <div class="form-floating mb-15px">
                        <input
                            v-model="model.password"
                            type="password"
                            class="form-control h-45px fs-13px"
                            placeholder="Password"
                            id="password"
                            :class="{ 'is-invalid': errors.has('password') }"
                        />
                        <label
                            for="password"
                            class="d-flex align-items-center fs-13px text-gray-600"
                            >Password</label
                        >
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('password')"
                        >
                            {{ errors.first("password") }}
                        </div>
                    </div>
                    <div class="mb-15px">
                        <select
                            v-model="model.guard"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('guard') }"
                            style="padding:12px"
                        >
                            <option value="admin">Staff</option>
                            <option value="agency">Agency</option>
                            <option value="shop">Shop</option>
                        </select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('guard')"
                        >
                            {{ errors.first("guard") }}
                        </div>
                    </div>
                    <div class="form-check mb-30px">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                            id="rememberMe"
                        />
                        <label class="form-check-label" for="rememberMe">
                            Remember Me
                        </label>
                    </div>
                    <div class="mb-15px">
                        <!-- <button
                            class="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                            @click.prevent="login"
                        >
                            Sign me in
                        </button> -->

                        <ts-button
                            type="submit"
                            class="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                            :waiting="loggingIn"
                        >
                            Sign me in</ts-button
                        >
                    </div>
                    <div class="mb-40px pb-40px text-inverse">
                        Do you forgot password ? Click
                        <router-link to="#" class="text-success"
                            >here</router-link
                        >
                        to recovery.
                    </div>
                    <hr class="bg-gray-600 opacity-2" />
                    <div
                        class="text-gray-600 text-center text-gray-500-darker mb-0"
                    >
                        &copy; TECHSTORE All Right Reserved 2021
                    </div>
                </form>
            </div>
            <!-- END login-content -->
        </div>
        <!-- END login-container -->
    </div>

    <!-- END login -->
</template>

<script>
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import AppOptions from "../config/AppOptions.vue";
import api from "@/api/login-api";

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
    name: "login",
    data() {
        return {
            errors: new Errors(),
            errorMessage: "",
            loggingIn: false,
            model: {
                guard: "admin",
                username: null,
                password: null
            }
        };
    },
    created() {
        AppOptions.appEmpty = true;
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appEmpty = false;
        next();
    },
    methods: {
        ...mapActions("auth/user", ["getCurrentUser"]),
        login() {
            this.loggingIn = true;
            this.errors = new Errors();
            this.errorMessage = "";
            api.login(this.model)
                .then(async response => {
                    this.$cookies
                        .set("token", response.data.access_token, "Session")
                        .set(
                            "refresh_token",
                            response.data.refresh_token,
                            "Session"
                        );

                    await this.fetchUser();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.errorMessage = error.message;
                    this.loggingIn = false;
                });
        },
        async fetchUser() {
            await this.getCurrentUser()
                .then( async response => {
                    localStorage.setItem(
                        "authUser",
                        JSON.stringify(response.data)
                    );
                    this.$store.commit("setAuthUser", response.data);

										this.$nextTick(() => {
                        AppOptions.pageEmpty = false;
                        this.$forceUpdate();
                    });
                    await this.$router.go(
                        this.$route.query.redirectTo || { name: "home" }
                    );
                })
                .catch(error => {
                    this.errorMessage = error.message;
										this.loggingIn = false;
                });
        }
    }
};
</script>
